<template>
  <div>
    <slot name="paginationBefore"></slot>
    <TablePaginationNav
      v-if="showPagination"
      v-model="pageItems"
      v-bind:items="orderedEquipmentList"
      v-bind:pageSize="pageSize"
      v-bind:nItems="nItems"
      v-on:loadNextPage="onLoadNextPage"
      class="navigation"
    />
    <slot name="paginationAfter"></slot>
    <div class="table-container">
      <table
        class="table table-condensed table-bordered table-hover dataTable table-striped table-responsive"
      >
        <thead>
          <tr class="sortable">
            <th style="display: none">#</th>
            <!-- alarm_status -->
            <tho
              v-if="showField('alarm_status')"
              name="alarm_status"
              title="alarm"
              v-on:sort="onSort"
            ></tho>
            <!-- connection_status -->
            <tho
              v-if="showField('connection_status')"
              name="connection_status"
              title="connection"
              v-on:sort="onSort"
            ></tho>
            <!-- connector -->
            <tho
              v-if="showField('connector')"
              name="name"
              title="connector"
              v-on:sort="onSort"
            ></tho>
            <!-- serial number -->
            <tho
              v-if="showField('serial_number')"
              name="serial_number"
              title="s_n"
              v-on:sort="onSort"
            ></tho>
            <!--location -->
            <tho
              v-if="showField('location')"
              name="location_name"
              title="location"
              v-on:sort="onSort"
            ></tho>
            <!-- device -->
            <tho
              v-if="showField('device')"
              name="device_name"
              title="device"
              v-on:sort="onSort"
            ></tho>
            <!-- process_area -->
            <tho
              v-if="showField('process_area')"
              name="device_process_area_name"
              title="process_area"
              v-on:sort="onSort"
            ></tho>
            <!-- CUSTOM FIELDS -->
            <tho
              v-for="(customField, index) in equipmentCustomFields"
              v-bind:key="index"
              v-on:sort="onSort"
              v-bind:name="customField.name"
              v-bind:title="customField.title"
            />
          </tr>
        </thead>
        <tbody v-if="equipmentList">
          <tr
            :class="[
              item.device_enabled && item.enabled ? 'text-info' : 'text-muted',
              device_id == item.device_id ? 'active' : '',
              'clicable-row'
            ]"
            data-testid="device"
            v-for="(item, index) in items"
            v-bind:key="index"
            v-bind:equipment="item"
            v-on:click.prevent.stop="onSelect(item)"
            v-bind:title="$t('titles.access_dashboard')"
          >
            <td style="display: none">{{ index }}</td>
            <!-- alarm_status -->
            <td
              v-if="
                showField('alarm_status') &&
                $can('view', 'HistoricoAlarmeAcesso')
              "
            >
              <span
                v-bind:class="
                  item.device_has_active_alarms ? 'text-danger' : ''
                "
              >
                <b class="icon-gap">
                  <router-link
                    v-bind:to="'/dashboard/alarms/' + item.id"
                    :title="$t('titles.access_alarms_history')"
                  >
                    <i
                      class="fa fa-exclamation-triangle text-danger clicable-icon"
                      v-if="item.device_has_active_alarms"
                    ></i>
                    <i class="fa fa-bell clicable-icon" v-else></i>
                  </router-link>
                </b>
                {{ $t(item.device_has_active_alarms ? "alarmed" : "normal") }}
              </span>
            </td>
            <!-- connection_status -->
            <td v-if="showField('connection_status')">
              <span v-bind:class="item.is_connected ? '' : 'text-danger'">
                <b class="icon-gap">
                  <i class="fa fa-link" v-if="item.is_connected"></i>
                  <i v-else class="fa fa-exclamation-triangle text-danger"></i>
                </b>
                {{ $tc(item.is_connected ? "connected" : "disconnected", 1) }}
              </span>
            </td>
            <!-- connector -->
            <td v-if="showField('connector')">
              <span :class="item.is_connected ? 'connected' : 'disconnected'">
                <!-- <ContractAlertIcon
                  visibility="connector"
                  style="max-width: 1em;"
                  v-model="contract_alert_icon"
                /> -->
                <b
                  :class="['icon-gap', { disabled: !item.enabled }]"
                  :title="item.connector_status.name"
                >
                  <i
                    class="fa fa-link text-success"
                    v-if="item.is_connected"
                  ></i>
                  <i class="fa fa-unlink text-danger" v-else></i>
                </b>
                {{ item.name || "" }}
                <b
                  class="icon-gap btn-xs pull-right"
                  v-if="canEditConnector"
                  @click.stop.prevent="editConnector(item)"
                  :title="`${$t('hints.access_to_configuration_form')} ${$tc(
                    'connector'
                  ).toLowerCase()}`"
                  ><i class="fa fa-wrench clicable-icon"></i
                ></b>
                <!-- <b
                  class="icon-gap pull-right"
                  :title="item.enabled ? $tc('enabled') : $tc('disabled')"
                >
                  <i
                    class="fa fa-toggle-on text-success"
                    v-if="item.enabled"
                  ></i>
                  <i class="fa fa-toggle-off text-muted" v-else></i>
                </b> -->
              </span>
            </td>
            <!-- serial number -->
            <td v-if="showField('serial_number')" data-testid="item.sn">
              {{ item.serial_number }}
            </td>
            <!-- location -->
            <td v-if="showField('location')">
              <span class="text-default">{{ item.location_name || "" }}</span>
            </td>
            <!-- device -->
            <!-- <td v-if="showField('device') && isMQTTConnector(item)">
              {{ item.name }}
            </td> -->
            <!-- <td
              v-else-if="showField('device')"
              :class="['not-clicable', { 'text-muted': !item.device_enabled }]"
            > -->
            <td
              v-if="showField('device')"
              class="device-column"
              :class="['not-clicable', { 'text-muted': !item.device_enabled }]"
              @click.stop.prevent="onDeviceSelected(item)"
            >
              <span
                :class="
                  (
                    isMQTTConnector(item)
                      ? item.is_connected
                      : item.device_is_connected
                  )
                    ? 'connected clicable'
                    : 'disconnected clicable'
                "
              >
                <b
                  :class="['icon-gap', { disabled: !item.device_enabled }]"
                  v-on:click.prevent.stop="onSelect(item)"
                >
                  <i
                    class="fa fa fa-link text-success"
                    :title="$tc('connected')"
                    v-if="item.device_is_connected"
                  ></i>
                  <i
                    class="fa fa fa-unlink text-danger"
                    :title="$tc('disconnected')"
                    v-else
                  ></i>
                </b>
                <b
                  v-if="$can('view', 'HistoricoAlarmeAcesso')"
                  :class="['icon-gap', { disabled: !item.device_enabled }]"
                >
                  <router-link
                    v-bind:to="'/dashboard/alarms/' + item.id"
                    :title="$t('titles.access_alarms_history')"
                  >
                    <i
                      class="fa fa-bell text-danger clicable-icon"
                      v-if="item.device_has_active_alarms"
                    ></i>
                    <i class="fa fa-bell clicable-icon" v-else></i>
                  </router-link>
                </b>
                {{ item.device_name || item.name || "" }}
                <i class="fa fa-dashboard"></i>
                <b
                  class="icon-gap pull-right"
                  v-if="canEditDevice"
                  @click.stop.prevent="editDevice(item)"
                  :title="`${$t('hints.access_to_configuration_form')} ${$tc(
                    'device'
                  ).toLowerCase()}`"
                  ><i class="fa fa-wrench clicable-icon"></i>
                </b>
                <!-- <b
                  class="icon-gap pull-right"
                  :title="
                    item.device_enabled ? $tc('enabled') : $tc('disabled')
                  "
                >
                  <i
                    class="fa fa-toggle-on text-success"
                    v-if="item.device_enabled"
                  ></i>
                  <i class="fa fa-toggle-off text-muted" v-else></i>
                </b> -->
              </span>
            </td>
            <!-- process area -->
            <td
              v-if="showField('process_area')"
              :class="{ 'text-muted': !item.device_enabled }"
            >
              <span>{{ item.device_process_area_name }}</span>
            </td>
            <!-- CUSTOM FIELDS -->
            <td
              v-for="(customField, index2) in equipmentCustomFields"
              v-bind:key="index2"
            >
              {{ getCustomFieldValue(item, customField) }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="10">{{ $t("no_equipment_was_found") }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { isMQTT } from "@/services/connector.js";
import TablePaginationNav from "@/components/table-pagination-nav.vue";
import tho from "@/components/tho.vue";
// import ContractAlertIcon from "@/components/contract-alert-icon.vue";
export default {
  name: "EquipmentTablePanel",
  components: {
    TablePaginationNav,
    tho
    // ContractAlertIcon
  },
  props: {
    searchEnabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    sortEnabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    equipmentList: {
      type: Array,
      required: false,
      default: () => []
    },
    ids: {
      type: Array,
      required: false,
      default: () => []
    },
    pagination: {
      type: Boolean,
      required: false,
      default: () => false
    },
    clientSort: {
      type: Boolean,
      default: () => true,
      required: false
    },
    inlineFilter: {
      type: Boolean,
      default: () => false,
      required: false
    }
  },
  data() {
    return {
      source: "connector",
      sort_def: {
        column: "dftIndex",
        asc: true
      },
      pageItems: [],
      serverSort: false,
      device_id: "",
      contract_alert_icon: false,
      fixedEquipmentProperties: [
        "alarm_status",
        "connection_status",
        "connector",
        "serial_number",
        "location",
        "device",
        "process_area"
      ]
    };
  },
  computed: {
    showPagination() {
      return this.pagination && this.pageSize && this.nItems > this.pageSize;
    },
    pageSize() {
      return parseInt(
        (this.$root.config &&
          this.$root.config.equipment_selection &&
          this.$root.config.equipment_selection.page_size) ||
          0 // no pagination
      );
    },
    nItems() {
      return (this.orderedEquipmentList || []).length;
    },
    items() {
      return this.pageSize &&
        this.pageItems.length &&
        this.nItems > this.pageSize
        ? this.pageItems
        : this.orderedEquipmentList;
    },
    orderedEquipmentList() {
      let self = this;
      let asc = self.sort_def.asc;
      let attr = self.sort_def.column;
      let ret = [];
      if (self.equipmentList) {
        let lst = JSON.parse(JSON.stringify(self.equipmentList));
        ret = lst
          .filter(function (i) {
            return self.ids && self.ids.length
              ? self.ids.indexOf(i.id) >= 0
              : true;
          })
          .map(function (i) {
            // i.sn = self.serial_number(i);
            for (var field_name in i.device || {}) {
              if (typeof i.device[field_name] == "object") {
                for (var field_name_deep in i.device[field_name] || {}) {
                  i["device_" + field_name + "_" + field_name_deep] =
                    i.device[field_name][field_name_deep];
                }
              } else {
                i["device_" + field_name] = i.device[field_name];
              }
            }
            i["dftIndex"] = `${
              i.device_has_active_alarms ? 0 : 1
            }${i.name.toUpperCase()}${i.device.name.toUpperCase()}`;
            return i;
          });
        if (!this.serverSort) {
          ret = ret.sort(function (a, b) {
            if (attr in a && attr in b) {
              if (asc ? a[attr] > b[attr] : b[attr] > a[attr]) return 1;
              if (asc ? b[attr] > a[attr] : a[attr] > b[attr]) return -1;
            }
            return 0;
          });
        }
      }
      return ret;
    },
    equipmentCustomFields() {
      let self = this;
      return (this.$root.config.equipment_selection.cols || []).filter(
        function (i) {
          return (
            (!("enabled" in i) || i.enabled) &&
            self.fixedEquipmentProperties.indexOf(i.name) == -1
          );
        }
      );
    },
    canEditConnector() {
      return this.$can("manage", "EquipamentoCadastro");
    },
    canEditDevice() {
      return this.$can("manage", "EstacaoCadastro");
    }
  },
  methods: {
    isMQTTConnector(item) {
      return isMQTT(item);
    },
    editConnector(connector) {
      let connector_id = (connector && connector.id) || 0;
      if (connector_id) {
        this.$router.push(`/dashboard/edit/connector/${connector_id}`);
      }
    },
    editDevice(item) {
      let device = (item && item.device) || null;
      let device_id = (device && device.id) || 0;
      let connector_id = (item && item.id) || 0;
      if (connector_id && device) {
        this.$router.push(
          `/dashboard/edit/connector/${connector_id}/device/${device_id}`
        );
      }
    },
    onPageChanged(lst) {
      this.$set(this, "pageItems", lst);
    },
    onLoadNextPage() {
      this.$emit("loadNextPage");
    },
    onSelect: function (item) {
      if (
        this.allowNull &&
        this.device_id &&
        this.device_id == item.device_id
      ) {
        this.device_id = "";
        this.$emit("select");
      } else {
        if (!this.allowNull) {
          this.openDeviceScreen(item);
          return;
        }
        this.device_id = item.device_id;
        let query = {
          connector_id: item.id,
          device_id: item.device_id
        };
        this.$emit("select", query);
      }
    },
    getCustomFieldValue(equipment, customField) {
      let value =
        equipment &&
        customField &&
        customField.name &&
        customField.name in equipment
          ? equipment[customField.name]
          : ("user_data" in equipment &&
              equipment.user_data &&
              "extended_properties" in equipment.user_data &&
              equipment.user_data.extended_properties &&
              customField.name in equipment.user_data.extended_properties &&
              equipment.user_data.extended_properties[customField.name]) ||
            "";
      // // exceptions
      // if (
      //   customField &&
      //   customField.name &&
      //   customField.name == "numero_serie_maquina"
      // ) {
      //   value = value || this.serial_number(equipment);
      // }
      return value;
    },
    // serial_number: function(equipment) {
    //   return equipment.numero_serie_maquina || equipment.numero_serie || "";
    // },
    onSort(attr) {
      this.sort_def.asc =
        attr == this.sort_def.column ? !this.sort_def.asc : true;
      this.sort_def.column = attr;
      if (!this.clientSort && this.showPagination) {
        // not all displayed columns can be sorted
        let api_field_map = {
          name: "connector_name",
          is_connected: "connector_status_name",
          device_name: "name",
          device_is_connected: "device_status_name",
          device_has_active_alarms: "number_active_alarms",
          device_process_area_name: "process_area_name"
        };
        if (this.sort_def.column in api_field_map) {
          let field =
            (this.sort_def.asc ? "" : "-") +
            api_field_map[this.sort_def.column];
          this.$emit("multiColumnSort", [field]);
          this.serverSort = true;
          return;
        }
      }
      this.serverSort = false;
    },
    showField(name) {
      let found = (this.$root.config.equipment_selection.cols || []).find(
        (i) => name == i.name && (!("enabled" in i) || i.enabled)
      );
      return found != undefined;
    },
    onDeviceSelected(item) {
      this.openDeviceScreen(item);
    },
    openDeviceScreen(item) {
      let device = item?.device || null;
      let path = `/dashboard/equipment/${item.id}`;
      if (device && device.screen_id) {
        path = `/dashboard/equipment/${item.id}/${device?.screen_id}`;
        if (this.$route.path == path) {
          if (this.$route.query.device_id == device.id) {
            return;
          }
          // since it is the same path, does not add on history.
          // it will make easier to return to the caller page instead
          let query = { ...this.$route.query };
          query.device_id = device.id;
          this.$router.replace({ query: query }).then(() => {
            console.log("changed");
          });
        } else {
          this.$router.push(`${path}?device_id=${device.id}`);
        }
        this.device_id = device.id;
      } else {
        if (this.$route.path == path) {
          return;
        }
        this.device_id = "";
        this.$router.push(path);
        return;
      }
    }
  },
  mounted() {
    // this.serverSort = !this.clientSort;
    this.device_id =
      this.$store.getters["deviceId"] ||
      this.$utils.gup("deviceId") ||
      this.$utils.gup("device_id") ||
      "";
    this.allowNull = this.device_id ? false : true;
  }
};
</script>

<style scoped>
.table-container {
  overflow: auto;
}

.clicable-row {
  opacity: 0.8;
}

.clicable-row:hover {
  cursor: pointer;
  opacity: 1;
}

.clicable-icon:hover {
  color: #f39c12;
  cursor: pointer;
  opacity: 1;
}

tr.sortable > th:hover {
  cursor: pointer;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  white-space: nowrap;
}

.table > tbody > tr.active > td {
  background-color: #337ab7;
  color: white;
}

button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}

a.alarm-link {
  min-width: 100px;
}

a.alarm-link .fa-link {
  color: transparent;
}

a.alarm-link:hover .fa-link {
  color: inherit;
  text-decoration: none;
}
.connected {
  color: inherit; /* TODO: validate it with team */
}
.disconnected {
  color: inherit; /* TODO: validate it with team */
}
.icon-gap {
  margin: 0 5px;
}
.icon-gap.disabled {
  opacity: 0.6;
}
.not-clicable {
  overflow: hidden;
}
.not-clicable:hover {
  cursor: default;
}

.clicable:hover {
  cursor: pointer;
}

.clicable-box {
  width: 100%;
  min-height: 16px;
  display: inline-block;
}

.device-column:hover {
  cursor: pointer;
  background-color: rgb(225, 243, 250);
}

.device-column > span > i.fa-dashboard {
  color: transparent;
  margin-left: 5px;
}

.device-column:hover > span > i.fa-dashboard {
  color: #337ab7;
  opacity: 0.7;
}
</style>
